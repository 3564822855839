//Begining of Logo.js file//

import React from "react";

import logo from "../assets/shared/images/avatar.jpg";

const Logo = () => {
  return (
    <span className="logo">
      <img src={logo} alt="Logo" />
      ComprasBitcoin.ar{" "}
    </span>
  );
};

export default Logo;

//End of Logo.js file//
